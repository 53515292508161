<script setup lang="ts">
import { useLayout } from '~/composables/layouts/useLayout';
import { useAuthStore } from '~/stores/auth/useAuthStore';

interface Props {
    isHovered: boolean; // активность ховера
}

withDefaults(defineProps<Props>(), {});

const authStore = useAuthStore();
const { isSettlementOperators, userRole } = storeToRefs(authStore);
const { getSettlementHeaderMenu } = useLayout();

const isLoading = ref(true);

onMounted(() => {
    isLoading.value = false;
});
</script>

<template>
    <div class="menu">
        <template
            v-for="item in Array(4)"
            :key="item"
        >
            <div v-if="isLoading" class="loader-wrapper">
                <v-skeleton-loader
                    color="grey50"
                    class="loader"
                    type="list-item"
                />
            </div>
        </template>
        <ClientOnly>
            <template v-if="isSettlementOperators">
                <template
                    v-for="item in getSettlementHeaderMenu(userRole)"
                    :key="item.id"
                >
                    <NuxtLink
                        :to="item.link" 
                        activeClass="active" 
                        class="menu-item"
                    >
                        <i :class="['menu-item__icon', item.icon, 'ri-lg']"/>
                        <span :class="['menu-item__text', { '_hover': isHovered }]">{{ item.text }}</span>
                    </NuxtLink>
                </template>
            </template>
            
        </ClientOnly>
    </div>
</template>

<style scoped lang="scss">
.loader {
    width: 100%;
    height: 22px;

    &.v-skeleton-loader {
        background-color: transparent !important;
    }

    :deep(.v-skeleton-loader__bone) {
        margin: 0;
    }

    &-wrapper {
        width: 46px;
        padding: 10px 12px;
    }
}

.menu {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &-item {
        display: flex;
        padding: 10px 12px;
        align-items: center;

        @include responsive( 'tablet' ) {
            margin: 12px 8px 0px 8px;
            padding: 0px 0px 20px 0px;
        }

        &.active {
            @include responsive( 'tablet' ) {
                border-bottom: 1px solid $color-red-500;
            }

            .menu-item__icon {
                color: $color-grey-950;
            }
            .menu-item__text {
                color: $color-grey-950;
            }
        }

        &__icon {
            width: 20px;
            height: 20px;
            color: $color-grey-400;
        }

        &__text {
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.16px;
            color: $color-grey-400;
            transition: all 0.2s ease-out;
            max-width: 0;
            opacity: 0;
            width: auto;
            white-space: nowrap;
            pointer-events: none;

            &._hover {
                padding-left: 8px;
                max-width: auto;
                opacity: 1;
                pointer-events: auto;
            }
        }
    }
}
</style>
import { ROUTES } from "~/constants/routes";
import type { ISettlementMenu } from "~/types/common/headerMenu";
import { RoleTypes, type RoleType } from "~/types/user/enum";

export const useLayout = () => {
    const { t } = useI18n();

    /**
    * Создает массив для пунктов меню в layout-е заселения менеджера
    * @param role роль пользователя
    * @returns массив пунктов меню
    */
    const getSettlementHeaderMenu = (role: RoleType): ISettlementMenu[] => {
        const settlement = {
            id: 1,
            link: ROUTES.settlementAdmin,
            icon: 'ri-home-line',
            text: t('routes.settlement'),
        }
        const users = {
            id: 2,
            link: ROUTES.users,
            icon: 'ri-user-line',
            text: t('routes.users'),
        }
        const acceptances = {
            id: 3,
            link: ROUTES.settlement_acceptances,
            icon: 'ri-calendar-line',
            text: t('routes.acceptances'),
        }
        const monitoring = {
            id: 4,
            link: ROUTES.settlement_monitoring,
            icon: 'ri-bar-chart-line',
            text: t('routes.settlementMonitoring'),
        }

        if (role === RoleTypes.SettlementManager) {
            return [settlement, users, acceptances];
        }
        if (role === RoleTypes.SettlementAdministrator) {
            return [settlement, users, monitoring, acceptances];
        }

        return [];
    };

    return {
        getSettlementHeaderMenu,
    };
};